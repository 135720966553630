




















































































































































































































































import { DataCoreSvcFeedhdlCreateFeedInput, DefaultApi } from 'generated/data-core-svc';
import { v4 as uuid } from 'uuid';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

import { DataSvcFeedRequest, DataSvcSchema } from '../../../generated/data-svc';
import { WalletsQuery } from '../../queries/transactionsPageQuery';
import { getCron } from './FeedCreate.utils';
@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
})
export default class FeedCreate extends BaseVue {
  public isLoading = false;
  public isLoadingDataSources = false;
  public isLoadingWallets = 0;
  public isValidating = false;
  public isCreatingFeed = false;

  // Validation related properties
  public validationStatus = ''; // pending, processing, complete, failed
  public validationPreviewData: Array<{ [key: string]: any }> = [];
  public validationStatistics: any = null;
  public validationErrors: Array<any> = [];
  public validationWarnings: Array<any> = [];
  public currentFeedId = '';
  public validationCheckInterval: number | null = null;

  // Properties for data sources
  public loadedDataSources: Array<{ id: string; name: string }> = [];
  public createDataSourceId = '';
  public newDataSourceSchema: any = null;
  public schemas: Array<any> = [];
  public paramsArray: Array<string> = [];
  public newFeedName = '';
  public isExistingDataSource = false;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSource!: { id: string; name: string } | null;
  public wallets: any = [];
  public feedType = 'transaction';
  public schemaType = 'bitwave-import-format';
  public scheduleType = 'disabled';
  public hasExpiry = false;
  public expiryDate = '';

  public isValidInput = false;
  public status = true;
  public min = 0;
  public max = 6;
  public cron = '0 0 * * *'; // Default cron at midnight daily

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oncreateFeedButtonClick')
  async oncreateFeedButtonClick() {
    const response = await this.createFeed();
    return response;
  }

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public onDataChange() {
    this.paramsArray =
      this.schemas.filter((schema: any) => schema.id === this.newDataSourceSchema?.id)[0]?.params?.split(',') || [];
  }

  public onFeedTypeChange() {
    this.createDataSourceId = '';
    this.loadDataSources();
    this.updateFeedName();
    this.validate();
  }

  public onDataSourceChange() {
    this.updateFeedName();
    this.validate();
  }

  // Add the validate method that was missing
  public validate() {
    // Validation logic
    this.isValidInput = !!this.createDataSourceId && this.validateNameLength(this.newFeedName);
    return this.isValidInput;
  }

  /**
   * Validates the length of a name
   * @param name The name to validate
   * @returns True if the name is valid (at least 3 characters)
   */
  public validateNameLength(name: string): boolean {
    return Boolean(name && name.length >= 3);
  }

  public onScheduleChange() {
    // Reset expiry when schedule changes
    if (this.scheduleType === 'disabled') {
      this.hasExpiry = false;
    }
    this.validate();
  }

  public updateFeedName() {
    const feedTypeLabel = 'Transaction';

    if (this.createDataSourceId) {
      const selectedDataSource = this.loadedDataSources.find((ds: any) => ds.id === this.createDataSourceId);
      if (selectedDataSource) {
        this.newFeedName = `${feedTypeLabel} Feed: ${selectedDataSource.name}`;
      } else {
        this.newFeedName = `${feedTypeLabel} Feed`;
      }
    } else {
      this.newFeedName = '';
    }
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      console.log('Loading data sources for organization:', this.orgId, 'and feed type:', this.feedType);
      const ds = DataApiManager.getInstance();
      // Map the feed type to the correct deprecated feed type expected by the API
      // The API expects specific values like 'transaction', 'import-svc-transaction', etc.
      let matchesFeedSchemaType;
      if (this.feedType === 'transaction') {
        // For transaction feeds, we use the schema type
        if (this.schemaType === 'bitwave-import-format') {
          matchesFeedSchemaType = 'import-svc-transaction';
        } else {
          matchesFeedSchemaType = 'transaction';
        }
      }
      console.log('Using matchesFeedSchemaType:', matchesFeedSchemaType);
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(
        this.orgId,
        matchesFeedSchemaType,
        undefined,
        {
          withCredentials: true,
        }
      );
      console.log('Data sources API response:', resp);
      if (resp.status === 200) {
        this.loadedDataSources = [];
        if (!resp.data.items || resp.data.items.length === 0) {
          console.warn('No data sources returned from API');
        } else {
          console.log(`Found ${resp.data.items.length} data sources`);
          for (const l of resp.data.items) {
            if (!l.id || !l.name) {
              console.warn('Skipping data source with missing id or name:', l);
              continue;
            }
            console.log('Adding data source:', l);
            this.loadedDataSources.push({
              name: l.name,
              id: l.id,
            });
          }
        }
        console.log('Loaded data sources:', this.loadedDataSources);

        // If we're in existing data source mode but have no sources, show a warning
        if (this.isExistingDataSource && this.loadedDataSources.length === 0) {
          this.showErrorSnackbar('No data sources found for the selected feed type. Please create a new data source.');
        }
      } else {
        const e = new Error(`Failed to load data sources. Status Code: ${resp.status}`);
        console.error('Data source API error:', e);
        this.showErrorSnackbar(e.message);
      }
    } catch (error) {
      console.error('Error loading data sources:', error);

      // Extract a user-friendly error message
      let errorMessage = 'Failed to load data sources';
      if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      } else {
        // Handle axios error objects
        // Use type assertion to any first to avoid TypeScript errors
        const axiosError = error as any;
        if (axiosError && axiosError.response) {
          errorMessage += `: ${axiosError.response.status} - ${axiosError.response.statusText || 'Unknown error'}`;

          // Log additional details for debugging
          if (axiosError.response.data) {
            console.error('API error response data:', axiosError.response.data);
          }
        }
      }

      this.showErrorSnackbar(errorMessage);
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public async createFeed() {
    this.validate();
    if (this.isValidInput) {
      this.isCreatingFeed = true;
      try {
        // Prepare the configuration object with all necessary feed parameters
        const configuration: Record<string, any> = {
          name: this.newFeedName,
          type: this.feedType,
          status: 'Enabled', // Always enabled since we removed the checkbox
          scheduleEnabled: this.scheduleType !== 'disabled', // Enable schedule if not disabled
          schemaType: this.schemaType, // Add the schema type
        };

        // Add schedule configuration if enabled
        if (this.scheduleType !== 'disabled') {
          // Set schedule type
          configuration.schedule = {
            type: this.scheduleType,
          };

          // Add expiry date if set
          if (this.hasExpiry && this.expiryDate) {
            configuration.schedule.expiryDate = this.expiryDate;
          }
        }

        // Create the feed input object according to the API specification
        const feedInput: DataCoreSvcFeedhdlCreateFeedInput = {
          configuration: configuration,
          datasourceId: this.createDataSourceId,
        };

        // Call the API to create the feed
        const ds = DataApiManager.getInstance();
        const res = await ds.handlersFeedhdlFeedHTTPHandlerCreate(this.orgId, feedInput, { withCredentials: true });

        if (res.status === 200) {
          const feedId = res.data?.feedId;
          this.currentFeedId = feedId || '';

          if (this.currentFeedId) {
            // Start validation process
            this.validationStatus = 'pending';
            this.startValidationCheck();

            // Show message that validation is in progress
            this.showSuccessSnackbar(
              `Feed "${this.newFeedName}" created and validation started. Please wait for validation to complete.`
            );
          } else {
            this.showSuccessSnackbar(`Feed "${this.newFeedName}" created successfully, but no feed ID was returned.`);
            this.$emit('onCreated');
          }
        } else {
          this.showErrorSnackbar(`Error creating feed: ${res.statusText || 'Unknown error'}`);
        }
        return res;
      } catch (e) {
        console.error('Error creating feed:', e);
        this.showErrorSnackbar(`Error creating feed: ${(e as Error).message || 'Unknown error'}`);
      } finally {
        // Don't set isCreatingFeed to false until validation is complete
        if (!this.currentFeedId) {
          this.isCreatingFeed = false;
        }
      }
    } else {
      this.showErrorSnackbar('Please fill in all required fields');
    }
  }

  // Start checking validation status periodically
  private startValidationCheck() {
    // Clear any existing interval
    if (this.validationCheckInterval) {
      clearInterval(this.validationCheckInterval);
    }

    // Check immediately
    this.checkValidationStatus();

    // Then check every 3 seconds
    this.validationCheckInterval = window.setInterval(() => {
      this.checkValidationStatus();
    }, 3000);
  }

  // Check the validation status of the current feed
  private async checkValidationStatus() {
    if (!this.currentFeedId) {
      return;
    }

    try {
      const ds = DataApiManager.getInstance();
      const res = await ds.handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(this.orgId, this.currentFeedId, {
        withCredentials: true,
      });

      if (res.status === 200 && res.data) {
        this.validationStatus = res.data.status || 'pending';
        this.validationPreviewData = res.data.previewData || [];
        this.validationStatistics = res.data.statistics || null;
        this.validationErrors = res.data.errors || [];
        this.validationWarnings = res.data.warnings || [];

        // If validation is complete or failed, stop checking
        if (this.validationStatus === 'Complete' || this.validationStatus === 'Failed') {
          if (this.validationCheckInterval) {
            clearInterval(this.validationCheckInterval);
            this.validationCheckInterval = null;
          }

          // Show appropriate messages based on validation status
          if (this.validationStatus === 'Complete' && this.validationErrors.length === 0) {
            this.showSuccessSnackbar('Feed validation completed successfully! You can now approve or reject the feed.');
            // Don't automatically approve - let the user decide
            this.isCreatingFeed = false;
          } else if (this.validationStatus === 'Failed' || this.validationErrors.length > 0) {
            this.showErrorSnackbar(`Feed validation failed with ${this.validationErrors.length} errors.`);
            this.isCreatingFeed = false;
          }
        }
      }
    } catch (e) {
      console.error('Error checking validation status:', e);
      // Don't show an error snackbar here as it might be too noisy during polling
    }
  }

  /**
   * Finalizes the feed by approving or rejecting it
   */
  async finalizeFeed(approve: boolean) {
    if (!this.currentFeedId) {
      return;
    }

    try {
      this.isCreatingFeed = true;

      // Use the proper API endpoint to update the feed approval status
      const ds = DataApiManager.getInstance();
      const response = await ds.handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
        this.orgId,
        this.currentFeedId,
        {
          approved: approve,
          reason: approve ? '' : 'Feed rejected by user',
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        // Show success notification
        this.showSuccessSnackbar(approve ? 'Feed approved successfully!' : 'Feed rejected successfully!');

        // Emit event to parent component
        this.$emit('feedFinalized', {
          feedId: this.currentFeedId,
          approved: approve,
        });

        // Reset the component state if needed
        if (!approve) {
          this.resetValidationState();
        }

        // Navigate back to the feeds list page if approved
        if (approve) {
          this.$router.push('/dataV2/feeds');
        }
      } else {
        // Show error notification
        this.showErrorSnackbar(`Failed to ${approve ? 'approve' : 'reject'} feed: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error finalizing feed:', error);
      // Show error notification
      this.showErrorSnackbar(
        `Error ${approve ? 'approving' : 'rejecting'} feed: ${(error as Error).message || 'Unknown error'}`
      );
    } finally {
      this.isCreatingFeed = false;
    }
  }

  /**
   * Resets the validation state
   */
  resetValidationState() {
    this.validationStatus = '';
    this.validationPreviewData = [];
    this.validationStatistics = null;
    this.validationErrors = [];
    this.validationWarnings = [];
    this.currentFeedId = '';
  }

  /**
   * Lifecycle hook - called when component is created
   */
  created() {
    console.log('FeedCreate component created');
    // Initialize data sources
    this.loadDataSources();
  }
}
