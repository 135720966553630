









































































































































































































import axios from 'axios';
import { DataCoreSvcFeedsFeedSummary } from 'generated/data-core-svc';
import { DataSvcSchema } from 'generated/data-svc/api';
import Component from 'vue-class-component';

import DataApiManager from '@/api/dataCoreApiManager';
import { Contact, ReconciliationStatus } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import { FeedInterfaceV2 } from '../data/Feed.types';
// import { getDataSourceSchemaNameByDataSourceId } from './DataImportUtils';
// import { FeedInterface } from './Feed.types';
import FeedCreate from './FeedCreate.vue';
// import FeedEdit from './FeedEdit.vue';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiPagination,
    UiModal,
    UiDataTable,
    UiTooltip,
    UiFormLabel,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    FeedCreate,
    UiPageHeader,
    // FeedEdit,
  },
})
export default class Feeds extends BaseVue {
  declare register?: any;

  public isCreatingFeed = false;
  public isEditingFeed = false;

  public pageLimit = '10';
  public open = false;
  public pageToken = 1;
  public currentFeedItem: FeedInterfaceV2 | null = null;
  public isLoadingFeeds = false;
  public isLoadingDataSource = false;
  public isStartFeedLoading = false;
  public feeds: DataCoreSvcFeedsFeedSummary[] = [];
  public dataSources: any = [];
  public isLoadingSchemas = false;
  public schemas: DataSvcSchema[] = [];
  public reportsLength = 0;

  async mounted() {
    await this.loadRequiredData();
  }

  readonly dropdownValues = [
    {
      value: 'executeFeedPipeline',
      label: 'Execute Feed Pipeline',
    },
    {
      value: 'editFeed',
      label: 'Edit Feed',
    },
    {
      value: 'deleteFeed',
      label: 'Delete Feed',
    },
  ];

  onDropdownSelect(valueSelected: any, feedItem: any) {
    console.log('onDropDownSelect value ' + valueSelected + ' feed item id' + feedItem.id);
    this.currentFeedItem = feedItem;

    switch (valueSelected) {
      case 'executeFeedPipeline':
        this.startSyncFeed(feedItem);
        break;

      case 'editFeed':
        this.handleEditFeed(feedItem);
        break;

      case 'deleteFeed':
        this.handleDeleteFeed(feedItem);
        break;
    }
  }

  // This method is used for direct execution from the UI button
  handleExecuteFeedButton(feed: FeedInterfaceV2) {
    // Directly call startSyncFeed without showing preview
    this.startSyncFeed(feed);
  }

  async startSyncFeed(feed: FeedInterfaceV2) {
    console.log('start sync feed ' + feed.id);
    this.isStartFeedLoading = true;
    try {
      const api = DataApiManager.getInstance();
      // First, initiate a manual run to get a feedRunId
      const initResp = await api.handlersFeedrunhdlFeedRunHandlerInitManualRun(this.orgId, feed.id, {
        withCredentials: true,
      });

      if (initResp.status === 200 && initResp.data?.feedRunId) {
        const feedRunId = initResp.data.feedRunId;

        // Then directly start the sync without preview
        const syncResp = await api.handlersFeedrunhdlFeedRunHandlerStartSync(this.orgId, feed.id, feedRunId, {
          withCredentials: true,
        });

        if (syncResp.status === 200) {
          this.showSuccessSnackbar(`Feed "${feed.name}" started successfully, Execution ID: ${feedRunId}`);
          // Refresh the feeds list to show updated status
          this.loadFeeds();
        } else {
          this.showErrorSnackbar(`Unable to start feed: ${syncResp.statusText || 'Unknown error'}`);
        }
      } else {
        this.showErrorSnackbar('Unable to initialize feed run, Please try again later');
      }
    } catch (err) {
      console.error('Error starting feed sync:', err);
      this.showErrorSnackbar(`Unable to start feed: ${(err as Error).message || 'Unknown error'}`);
    } finally {
      this.isStartFeedLoading = false;
    }
  }

  handleEditFeed(feed: FeedInterfaceV2) {
    this.currentFeedItem = feed;
    this.isEditingFeed = true;
  }

  async handleDeleteFeed(feed: FeedInterfaceV2) {
    if (!confirm(`Are you sure you want to delete the feed "${feed.name}"?`)) {
      return;
    }

    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersFeedhdlFeedHTTPHandlerDelete(this.orgId, feed.id, { withCredentials: true });

      if (resp.status === 200) {
        this.showSuccessSnackbar(`Feed "${feed.name}" deleted successfully`);
        // Refresh the feeds list
        await this.loadFeeds();
      } else {
        this.showErrorSnackbar(`Error deleting feed: ${resp.statusText || 'Unknown error'}`);
      }
    } catch (err) {
      console.error('Error deleting feed:', err);
      this.showErrorSnackbar(`Error deleting feed: ${(err as Error).message || 'Unknown error'}`);
    }
  }

  async onFeedCreatedHandler() {
    this.isCreatingFeed = false;
    await this.loadRequiredData();
  }

  async onFeedEditHandler() {
    console.log('on feed edit handler');
    this.isEditingFeed = false;
    await this.loadRequiredData();
  }

  async confirmModalHandler() {
    this.open = false;

    if (this.currentFeedItem?.id) {
      // Use startSyncFeed instead of executeFeedPipeline
      await this.startSyncFeed(this.currentFeedItem);
    } else {
      console.log('no feed selected');
    }
    this.currentFeedItem = null;
  }

  closeModalHandler() {
    this.currentFeedItem = null;
    this.open = false;
  }

  standardizeFrequency(frequency: string): string {
    if (!frequency) {
      return 'Manual';
    }

    switch (frequency) {
      case '0 0 * * *':
        return 'Nightly';
      case '0 0 1 * *':
        return 'Monthly';
      case '0 0 * * 0':
        return 'Weekly';
      default:
        return frequency;
    }
  }

  async executeFeedPipeline(feedId: string) {
    console.log('execute feed pipeline');
    const api = DataApiManager.getInstance();
    const userObj = this.$store.state.user;
    this.isStartFeedLoading = true;
    try {
      // Use the feed run handler to initiate a manual run
      const resp = await api.handlersFeedrunhdlFeedRunHandlerInitManualRun(this.orgId, feedId, {
        withCredentials: true,
      });

      if (resp.status === 200) {
        // The response should contain the feed run ID
        const feedRunId = resp.data?.feedRunId;
        this.showSuccessSnackbar(
          feedRunId ? `Feed Triggered Successfully, Execution ID: ${feedRunId}` : 'Feed Triggered Successfully'
        );

        // Refresh the feeds list to show updated status
        this.loadFeeds();
      } else {
        this.showErrorSnackbar('Unable to Trigger Feed, Please try after some time');
      }
    } catch (err) {
      console.error('Error executing feed:', err);
      this.showErrorSnackbar('Unable to Trigger Feed, Please try after some time');
    } finally {
      this.isStartFeedLoading = false;
    }
  }

  async onDataSourceArgChanged() {
    // Reset page token and keys before loading another data source
    this.pageToken = 1;
    await this.loadFeeds();
  }

  async loadRequiredData() {
    const loadDataSourceAndSchema = [/* this.loadSchemas(), */ this.loadDataSources()];
    await Promise.all(loadDataSourceAndSchema);
    await this.loadFeeds();
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(
        this.orgId, // orgId
        undefined, // matchesFeedSchemaType
        undefined, // pageToken
        {
          // options
          withCredentials: true,
        }
      );
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  getDataSourceName(dataSourceId: string): string {
    if (this.dataSources.length) {
      const item = this.dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
      return item ? item?.name : dataSourceId;
    }
    return dataSourceId;
  }

  async loadFeeds() {
    this.isLoadingFeeds = true;
    try {
      // Use DataApiManager.getInstance() which uses api2Url for feed endpoints
      // according to the API versioning pattern (/orgs/{orgId}/... without v3)
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersFeedhdlFeedHTTPHandlerList(this.orgId, undefined, undefined, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.feeds = (resp.data.items ?? []).map((item) => {
          const dataSources = this.dataSources;
          return {
            createdBy: item.createdBy,
            lastModifiedBy: item.updatedBy,
            id: item.feedId,
            createdAt: item.createdAt,
            lastModifiedAt: item.updatedAt,
            name: item.name,
            dataSourceId: item.dataSourceId,
            status: item.status,
            frequency: item.cronExpression,
          };
        });
        this.reportsLength = resp.data.items?.length ?? 0;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        console.log((e as Error).message);
        this.showErrorSnackbar('Something went wrong, Please try again later.');
      }
    } catch (err) {
      console.log((err as Error).message);
      this.showErrorSnackbar('Something went wrong, Please try again later.');
    } finally {
      this.isLoadingFeeds = false;
    }
  }

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'feedId',
      label: 'Feed Id',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'Feed Name',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceId',
      label: 'Data Source Id',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceName',
      label: 'Data Source Name',
      defaultVisibility: true,
    },

    {
      id: 'frequency',
      label: 'Frequency',
      defaultVisibility: true,
    },
    {
      id: 'createdBy',
      label: 'Created By',
      defaultVisibility: true,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      defaultVisibility: true,
    },
    {
      id: 'lastModifiedBy',
      label: 'Last Edit By',
      defaultVisibility: false,
    },
    {
      id: 'lastModifiedAt',
      label: 'Last Edit At',
      defaultVisibility: false,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayRegister() {
    console.log(this.register);
    return this.isLoading ? [] : (this.register?.items ?? []).filter(isDefined);
  }

  public async loadRegister() {
    if (this.$store.state.currentOrg && this.walletId !== 'select') {
      this.isLoading = 1;
      let currencyId;
      let hasError = false;
      if (this.ticker && this.ticker !== null) {
        const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;
        const resp = await axios.get(assetsApiUrl);
        if (resp === undefined || resp.status !== 200) {
          hasError = true;
          this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
        } else {
          currencyId = `COIN.${resp.data.coinId}`;
        }
      }
      if (!hasError) {
        const res = await this.$apollo.query({
          query: RegisterQuery,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            filter: {
              walletId: this.walletId,
              endDate: this.endDate,
              currencyId,
            },
          },
        });
        this.register = res.data.register;
      }

      this.isLoading = 0;
    }
  }

  public showMenuOn: any | null = null;
  public expandedTxn: any | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: any | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
